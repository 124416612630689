/* Typing effect and Cursor Styling */

.css-typing p {
    border-right: .15em solid orange;
    width: 320px;
    font-size: 1pc;
    white-space: nowrap;
    overflow: hidden;
    


  }
  .css-typing p:nth-child(1) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .css-typing p:nth-child(2) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing p:nth-child(3) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

    
  .css-typing p:nth-child(4) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(5) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing p:nth-child(6) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(7) {
    width: 320px;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  /*.css-typing p:nth-child(3) {
    width: 25em;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    animation: type2 5s steps(20, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  
  }
  */

  .css-typing p:nth-child(8) {
    width: 280px;
    opacity: 0;
    -webkit-animation: type3 2s steps(40, end), blink .5s alternate infinite step-end ;
    animation: type3 2s steps(40, end), blink .5s alternate infinite step-end ;
    -webkit-animation-delay: 12s;
    animation-delay: 12s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;



  }

  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      
    }
    
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
    
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
/* Glowing dots*/
light {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  margin-right: 13px;
  border-radius: 100%;
  margin-top: -35px;
  margin-bottom: 5px;
  align-self: flex-end;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5), 0px 0px 10px 2px rgba(0, 255, 135, 0.3);
  animation: pulse 1s alternate infinite;
  -webkit-animation: pulse 1s alternate infinite;
}

@keyframes pulse {
  0% {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5), 0px 0px 5px 2px rgba(0, 255, 135, 0.3);
  }
  100% {
    background: rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5), 0px 0px 15px 2px rgba(0, 255, 135, 1);
  }
}

@keyframes caret {
    50% {
      border-color: transparent;
    }
  }

/* align self right*/
.align-item-right {
    text-align: end;
    margin-right: 30px;

}

.narrow-2 {
    align-items: center;
    
 
}




